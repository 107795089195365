import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, iif } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@fury/services/theme.service';
import { LoginService } from '../../../app/pages/authentication/login/login.service';
import { AuthService } from '../../shared/services/auth.service';

declare var $: any;

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  public urlImage:string;
  public img:any; 
  public email:string;
  public role_id:number;
  public name :string;
  public firstname:string;
  public surname :string;
  public position:string;
  public test:string;
  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;

  constructor(private router: Router,
              private sidenavService: SidenavService,
              private themeService: ThemeService) {

              this.img = LoginService.getUser().img;

              if(!this.img){
                this.img = "no-image.jpg";
              }

              this.urlImage =  '../assets/img/avatars/'+ this.img;
}

  ngOnInit() {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );
    
    this.LogService();
  }

  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {
  }
  
  LogService(){
    this.role_id = LoginService.getUser().id_role;
    this.email = LoginService.getUser().email;
    this.name = LoginService.getUser().name;
    this.firstname = LoginService.getUser().firstname;
    this.surname = LoginService.getUser().surname;
    
    if(this.role_id == 0){
      this.position = 'Developer';
    }
  }
  public onLoggedOut() {
    AuthService.loggedOut();
  }
}
